import "../globals.scss";
import React from "react";
import MusicFooter from "../components/MusicFooter";

function Home() {
  return (
    <div className="wrapper">
      <div className="music-page-container">
        {/* <div className="nav">
          <a href="#home">HOME</a>
          <a href="#music">MUSIC</a>
          <a href="#live">LIVE</a>
        </div> */}
      </div>
      <div className="bg-image">
        <a className="anchor" id="home"></a>
        <div className="title">Mark Darling</div>
        <div className="coming-soon">
          <p className="subtitle">New Album</p>
          <p className="subtitle">Ghosts</p>
          <div className="music-links">
            <a
              className="fa-brands fa-apple"
              href="https://music.apple.com/us/album/ghosts/1677686934"
            ></a>
            <a
              className="fa-brands fa-amazon"
              href="https://www.amazon.com/dp/B0BYQSFW3L/ref=mp_s_a_1_2?crid=2Q1BSD54HP18N&keywords=Mark+darling+ghosts&qid=1679344616&s=dmusic&sprefix=mark+darling+ghosts%2Caps%2C157&sr=1-2"
            ></a>
            <a
              className="fa-brands fa-spotify"
              href="https://prf.hn/click/camref:1101ljvYv/pubref:albumuuid%3DD94071C3-2FF1-4FE4-8C21FDEC4130E8FE/destination:https://open.spotify.com/album/4m8x4BgMJAhEojrIm5uE94"
            ></a>
            <a
              className="fa-brands fa-deezer"
              href="https://www.deezer.com/us/album/418623837"
            ></a>
            <a
              className="fa-brands fa-youtube"
              href="https://music.youtube.com/playlist?list=OLAK5uy_mtLqzuINe15h9i6Bsqqf4K68s_ovBZALI"
            ></a>
          </div>
          <p className="subtext">Out now on all music platforms</p>
        </div>
        <MusicFooter />
      </div>
      <div className="video">
        <p>Video</p>
        <iframe
          width="600"
          height="372"
          src="https://www.youtube.com/embed/dWnPC9hF3Z0"
          title="Two Mountain Streams - Mark Darling"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <a className="anchor" id="music"></a>
      <div className="music">
        <p>Music</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=OLAK5uy_ldiokwoJC1KZgyrzv_qNVHq5PGj6YvyJM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
      <a className="anchor" id="live"></a>
      <div className="live">
        <p>Live Dates</p>
        <p>No Upcoming Shows</p>
      </div>
    </div>
  );
}

export default Home;
